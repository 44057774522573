import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { Document } from "iconsax-react";
import { useEffect, useState } from "react";
import {
  useGetAccountQuery,
  useLazyCmsLegalDocumentByIdQuery,
} from "services/osare";

export default function OnboardAgreements() {
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [triggerCmsFetch, cmsFetchResult] = useLazyCmsLegalDocumentByIdQuery();
  const [legalCopy, setLegalCopy] = useState(null);
  const { data: accountData, isFetching: accountFetching } =
    useGetAccountQuery();

  useEffect(() => {
    if (cmsFetchResult.isSuccess) {
      // If CMS was successfully fetched,
      // parse the tags and replace with account data
      console.log("CMS Agreement Data", cmsFetchResult.data);
      console.log("Account Data", accountData);
      setLegalCopy(cmsFetchResult.data.results.document);
    }
  }, [cmsFetchResult]);

  // eslint-disable-next-line no-unused-vars
  const openAgreementModal = (agreementCmsId) => {
    setLegalCopy(null);
    setIsAgreementModalOpen(true);
    triggerCmsFetch({ id: agreementCmsId });
  };

  const AgreementModalComponent = () => {
    return (
      <Modal open={isAgreementModalOpen}>
        <ModalDialog
          color="primary"
          layout="center"
          size="md"
          variant="outlined"
        >
          <ModalClose
            onClick={() => {
              setIsAgreementModalOpen(false);
              setLegalCopy(null);
            }}
          />

          {cmsFetchResult.isFetching ? (
            <>
              <Typography gutterBottom level="title-lg">
                Just a moment...
              </Typography>
              <LinearProgress />
            </>
          ) : (
            <>
              <Typography level="title-lg">{legalCopy?.title}</Typography>
              <div
                style={{ overflowY: "scroll" }}
                dangerouslySetInnerHTML={{
                  __html: legalCopy?.body,
                }}
              />
            </>
          )}
        </ModalDialog>
      </Modal>
    );
  };

  const LoadingComponent = () => {
    return <Typography>Loading...</Typography>;
  };

  return (
    <>
      <AgreementModalComponent />

      {accountFetching ? (
        <LoadingComponent />
      ) : (
        <>
          <Box mt={5} textAlign={"center"}>
            <Typography level="h2">Legal Agreements</Typography>
            <Typography level="body-lg">
              Please read and confirm that you agree to the following
              agreements.
            </Typography>
          </Box>

          <Grid container spacing={3} m={3}>
            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={<Checkbox label="I agree" />}
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link onClick={() => openAgreementModal("agency_agreement")}>
                    Agency Agreement
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={<Checkbox label="I agree" />}
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link
                    onClick={() =>
                      openAgreementModal("agency_terms_conditions")
                    }
                  >
                    Agency Terms &amp; Conditions
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6}>
              <Alert
                startDecorator={<Document />}
                endDecorator={<Checkbox label="I agree" />}
                variant="outlined"
                color="primary"
              >
                <Typography>
                  <Link onClick={() => openAgreementModal("privacy_policy")}>
                    Privacy Policy
                  </Link>
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={0} md={3}></Grid>

            <Grid xs={0} md={3}></Grid>
            <Grid mt={3} xs={12} md={6}>
              <Button disabled fullWidth>
                Continue
              </Button>
            </Grid>
            <Grid xs={0} md={3}></Grid>
          </Grid>
        </>
      )}
    </>
  );
}
