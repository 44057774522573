import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import DocumentViewerModal from "pages/Dash/Components/DocumentViewerModal/DocumentViewerModal";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreateAccountMutation,
  useGetConfigQuery,
  useLazyGetAccountLinkQuery,
  useLazyGetAccountQuery,
} from "services/osare";

export default function OnboardWelcome() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [getAccount, accountResponse] = useLazyGetAccountQuery();
  const [createAccount, createAccountResponse] = useCreateAccountMutation();
  const [getAccountLink, getAccountLinkResult] = useLazyGetAccountLinkQuery();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const [redirectingToStripe, setRedirectingToStripe] = React.useState(false);
  const [onboardingUrl, setOnboardingUrl] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [documentViewerOpen, setDocumentViewerOpen] = React.useState(false);
  const [documentId, setDocumentId] = React.useState(null);

  const openDocumentViewer = (documentId) => {
    setDocumentId(documentId);
    setDocumentViewerOpen(true);
  };

  /**
   * START: Step 0 - Auth effect fire
   */
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        createAccount({});
      } else {
        navigate("/login");
      }
    });
  }, []);

  /**
   * Step 1 - Attempt to create an account response.
   * The account creation endpoint is an
   * idempotent endpoint
   */
  useEffect(() => {
    if (createAccountResponse.isSuccess) {
      /**
       * On success, go to the getAccount flow
       */
      getAccount();
    } else {
      if (createAccountResponse.isError) {
        console.error("createAccountResponse.isError:", createAccountResponse);
        console.log("Retrrying in 5s...");

        setTimeout(() => {
          // Restart the create account flow
          createAccount({});
        }, 5000);
      }
    }
  }, [createAccountResponse]);

  /**
   * Step 2 - Get Account Response
   */
  useEffect(() => {
    /**
     * Was there an error?
     */
    if (accountResponse.isError) {
      console.log("Errored, retrying in 5s...");
      setTimeout(() => {
        getAccount();
      }, 5000);
    }

    /**
     * Otherwise if the request was successful...
     */
    if (accountResponse.isSuccess) {
      console.log("Account response successful:", accountResponse.data);
      if (
        accountResponse.data.paymentGatewayData.requirements.eventually_due
          .length > 0
      ) {
        console.log(
          "accountResponse.data.paymentGatewayData.requirements.eventually_due:",
          accountResponse.data.paymentGatewayData.requirements.eventually_due
        );

        getAccountLink();
      } else {
        navigate("/agreements");
      }
    }
  }, [accountResponse]);

  /**
   * Step 3 - Account Link Response (if required)
   */
  useEffect(() => {
    if (getAccountLinkResult.isSuccess && getAccountLinkResult.data.url) {
      setRedirectingToStripe(true);
      setOnboardingUrl(getAccountLinkResult.data.url);
    }
  }, [getAccountLinkResult]);

  return (
    <>
      {user ? (
        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
          <DocumentViewerModal
            documentId={documentId}
            open={documentViewerOpen}
            setOpenFn={setDocumentViewerOpen}
          />
          <Grid xs></Grid>
          <Grid xs={6} marginTop={5}>
            <Typography level="h2" textAlign={"center"}>
              Hi {auth?.currentUser?.displayName} 👋
            </Typography>
            <Typography level="h3" textAlign={"center"}>
              Welcome to {isConfigLoading ? "" : configData.name}
            </Typography>
            <Box marginTop={5} />
            {redirectingToStripe ? (
              <LinearProgress determinate value={100} color="success" />
            ) : (
              <LinearProgress />
            )}
            <Box marginTop={5} />
            {(accountResponse.isLoading || createAccountResponse.isLoading) && (
              <Typography level={"body-lg"} textAlign={"center"}>
                We&apos;re just checking a few details, hang tight...
              </Typography>
            )}
            {redirectingToStripe && (
              <Card color="success">
                <CardContent>
                  <Typography
                    gutterBottom
                    level={"title-lg"}
                    textAlign={"center"}
                  >
                    Please complete your account setup with Stripe
                  </Typography>
                  <Typography
                    gutterBottom
                    level={"body-md"}
                    textAlign={"center"}
                  >
                    Stripe is a secure payment provider. We use Stripe to take
                    payments from travellers and to pay you.
                  </Typography>
                  <Typography
                    gutterBottom
                    level={"body-md"}
                    textAlign={"center"}
                  >
                    Stripe will ask for your business, bank, contact and
                    identity details to ensure that we are both protected by the
                    correct governing laws and agreements and that we have
                    everything we need to allow travellers to buy your products.
                  </Typography>
                  <Typography
                    gutterBottom
                    level={"body-md"}
                    textAlign={"center"}
                  >
                    Once you&apos;ve completed your account setup with Stripe,
                    you&apos;ll be redirected back here.
                  </Typography>

                  <Typography
                    marginY={2}
                    fontWeight={"bold"}
                    textAlign={"center"}
                  >
                    By continuing, i agree that i have read and understood the{" "}
                    <Link
                      onClick={() => openDocumentViewer("terms_of_service")}
                    >
                      Website Terms of Service
                    </Link>
                    ,{" "}
                    <Link
                      onClick={() =>
                        openDocumentViewer("booking_terms_of_service")
                      }
                    >
                      Booking Terms of Service
                    </Link>{" "}
                    ,{" "}
                    <Link onClick={() => openDocumentViewer("privacy_policy")}>
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={() => openDocumentViewer("agency_agreement")}
                    >
                      Agency Agreement
                    </Link>
                    .
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    onClick={() => (window.location.href = onboardingUrl)}
                    fullWidth
                    variant="solid"
                    color="success"
                  >
                    Agree and continue to Stripe
                  </Button>
                </CardActions>
              </Card>
            )}
            <Box textAlign={"center"} marginTop={2}>
              <Button
                onClick={() => auth.signOut()}
                color="danger"
                variant="plain"
              >
                Log out and exit
              </Button>
            </Box>
          </Grid>
          <Grid xs></Grid>
        </Grid>
      ) : (
        <Grid textAlign={"center"} marginTop={5}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
